1<template>
  <div class="my-form approvalForm">
    <template v-if="approvalFlag">
      <div class="cnryj">处理人意见区</div>

      <a-form :form="form" style="margin-top: 20px">
        <a-form-item label="">
          <a-radio-group @change="handleRadioChage" v-decorator="['spfs', { initialValue: '1' }]">
            <a-radio value="1"> 同意 </a-radio>
            <a-radio value="2" style="margin-left: 50px"> 驳回 </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="意见建议" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-textarea :rows="4" placeholder="请输入" v-decorator="['nbyj', { initialValue: '已阅' }]"></a-textarea>
        </a-form-item>

        <div class="submit-bt">
          <a-button type="primary" @click="handleSubmit" size="large"> 提交 </a-button>
        </div>
      </a-form>
      <div class="cnryj">处理流程</div>
    </template>
    <div class="process">
      <processMess :documentNodeList="billMess.documentNodeList"></processMess>
    </div>
  </div>
</template>

<script>
import { documentBillAudit } from '@/api/modular/fileStream/documentManage'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
export default {
  props: {
    params: {
      type: Object,
      default() {
        return {}
      },
    },
    billMess: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    processMess,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
    }
  },
  computed: {
    approvalFlag() {
      const { documentNodeList } = this.billMess
      let nodeInfo =
        documentNodeList &&
        documentNodeList
          .filter((item) => item.sort != -1 && item.sort != 99 && item.sort != 100)
          .find((item) => item.progress == 'Y')
      let flagInfo =
        nodeInfo &&
        nodeInfo?.nodes
          .filter((item) => {
            return item.userId == userId
          })
          .find((item) => item.approvalFlag == 0)
      if (flagInfo) {
        let approvalFlag = flagInfo?.approvalFlag
        return +approvalFlag == 0
      }
    },
  },

  methods: {
    handleRadioChage({ target: { value } }) {
      this.form.setFieldsValue({
        nbyj: value === '2' ? '驳回' : '已阅',
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        console.log('Received values of form: ', values)
        if (!err) {
          const { approvalId, nodeId, id: billId } = this.params
          documentBillAudit({
            approvalId,
            billId,
            nodeId,
            opinion: values.nbyj,
            status: Number(values.spfs),
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(values.nbyj === '2' ? '流程终止成功' : '审批成功')
              } else {
                this.$message.error(res.message)
              }
              this.$nextTick(() => {
                this.$router.push({ name: 'fileStreamDocumentApproval', replace: true })
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
  align-items: center;
}
.font {
  font-size: 16px;
}

.process {
  margin-top: 20px;
}

.my-form {
  width: 478px;
  margin-left: 20px;
  background: #fff;
  padding: 20px 25px;
  &.approvalForm {
    padding-top: 100px;
  }
}
::v-deep .ant-form-item-label {
  text-align: left;
}

.submit-bt {
  text-align: right;
  margin: 60px 0 40px;
  button {
    width: 66px;
    height: 36px;
    background: #3b9afa;
    border-radius: 4px;
  }
}

.cnryj {
  background: #e8f3fe;
  border-radius: 4px 4px 0px 0px;
  font-size: 16px;
  color: #3b9afa;
  padding: 10px 20px;
}
</style>
